import React from 'react';
import { Link, FormattedMessage } from 'gatsby-plugin-intl';
import componentStyles from './SubPageBottomNav.module.scss';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const SubPageBottomNav = ({ prevLink, nextLink, ...props }) => {
	let justifySetting;
	if (prevLink && nextLink) justifySetting = 'space-between';
	else if (nextLink) justifySetting = 'flex-end';
	else justifySetting = 'flex-start';

	return (
		<nav className={componentStyles.subPageBottomNav}>
			<ul role='presentation' style={{ justifyContent: `${justifySetting}` }}>
				{prevLink && (
					<li role='none' className={componentStyles.prevLink}>
						<Link to={prevLink.path} role='menuitem'>
							<FaChevronLeft className={componentStyles.icon} />
							<div>
								<b>
									<FormattedMessage id='nav.prevPage' />
								</b>
								<br />
								{prevLink.text}
							</div>
						</Link>
					</li>
				)}

				{nextLink && (
					<li role='none' className={componentStyles.nextLink}>
						<Link to={nextLink.path} role='menuitem'>
							<div>
								<b>
									<FormattedMessage id='nav.nextPage' />
								</b>
								<br />
								{nextLink.text}
							</div>
							<FaChevronRight className={componentStyles.icon} />
						</Link>
					</li>
				)}
			</ul>
		</nav>
	);
};

export default SubPageBottomNav;
